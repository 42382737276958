import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Button from "components/_ui/Button";
import Layout from "components/Layout";
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import MailchimpForm from "components/MailchimpForm";

const EngagementHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        margin: 0 auto;
        width: 70%;
    }
`

const EngagementHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

const EngagementTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const EngagementDescription = styled("p")`
    text-align: center;
`

const EngagementBody = styled("div")`
    max-width: 550px;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }

    h2, h3 {
        padding-top: 1em;
    }
`

const SpeakingLink = styled(OutboundLink)`
    margin-top: 3em;
    display: block;
    text-align: center;
    margin-bottom: 7em;
`

const Engagement = ({ engagement, meta }) => {
    return (
        <>
            <Helmet
                title={`${engagement.engagement_title[0].text} | Carolyn Stransky`}
                htmlAttributes={{ lang: "en" }}
                link={[
                    {
                        rel: `canonical`,
                        href: `https://workwithcarolyn.com/speaking/${engagement._meta.uid}`
                    }
                ]}
                meta={[
                    {
                        name: `description`,
                        content: `${engagement.engagement_preview_description[0].text}`,
                    },
                    {
                        property: `og:title`,
                        content: `${engagement.engagement_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        property: `og:description`,
                        content: `${engagement.engagement_preview_description[0].text}`,
                    },
                    {
                        name: `og:image`,
                        content: `${engagement.engagement_preview_image.url}`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: `${engagement.engagement_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        name: `twitter:description`,
                        content: `${engagement.engagement_preview_description[0].text}`,
                    },
                    {
                        name: `twitter:image`,
                        content: `${engagement.engagement_preview_image.url}`,
                    }
                ].concat(meta)}
            />
            <Layout>
                <EngagementTitle>
                    {RichText.render(engagement.engagement_title)}
                    <EngagementDescription>
                        {RichText.render(engagement.engagement_preview_description)}
                    </EngagementDescription>
                </EngagementTitle>
                    {engagement.engagement_hero_image && (
                    <EngagementHeroContainer>
                        <img src={engagement.engagement_hero_image.url} alt="" />
                        <EngagementHeroAnnotation>
                            {RichText.render(engagement.engagement_hero_annotation)}
                        </EngagementHeroAnnotation>
                    </EngagementHeroContainer>
                )}
                <EngagementBody>
                    {RichText.render(engagement.engagement_body)}
                    <SpeakingLink href={`mailto:hello@workwithcarolyn.com?subject=${engagement.engagement_subject_line[0].text}`} target="_blank" rel="noopener noreferrer">
                        <Button className="Button--secondary">
                            {engagement.engagement_link_text[0].text}
                        </Button>
                    </SpeakingLink>
                    <MailchimpForm
                        titleText="Like my work?"
                    />
                </EngagementBody>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const engagementContent = data.prismic.allEngagements.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Engagement engagement={engagementContent} meta={meta}/>
    )
}

Engagement.propTypes = {
    engagement: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query EngagementQuery($uid: String) {
        prismic {
            allEngagements(uid: $uid) {
                edges {
                    node {
                        engagement_title
                        engagement_hero_image
                        engagement_hero_annotation
                        engagement_preview_image
                        engagement_preview_emoji
                        engagement_date
                        engagement_body
                        engagement_link_text
                        engagement_subject_line
                        engagement_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                baseOG
            }
        }
    }
`